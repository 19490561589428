"use client";
import { SeriesSubscriptionHeaderContent } from "@/components/SeriesSubscriptionHeaderContent";
import { SubscriptionHeaderContent } from "@/components/SubscriptionHeaderContent";
import React, { useState } from "react";
import { ContentData } from "@/cms/types";
import { SectionProps } from "../Section";
import { ProductVariantList } from "@/cms/types/objects";
import { PortableTextSection } from "@/portable-text/PortableTextSection";
import { truncatePortableText } from "@/portable-text/proseClamper";
import { ImageContentSection } from "../components/ImageContentSection";
import { BookContent } from "@/products/types";
import { getThemeClassName } from "@/theming/selectors";
import classNames from "classnames";
import { SubscriptionCampaignSection } from "./types";
import { Section } from "../types";
import { SanityIcon } from "@/cms/icons/Icon";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import { getPageLink } from "@/navigation/selectors";
import { SubscriptionProduct } from "@cappelendamm/e-commerce-services";

type SubscriptionCampaignFocusProps = ContentData<SubscriptionCampaignSection> &
  Omit<SectionProps, keyof Section> & {
    subscriptionProduct: SubscriptionProduct;
  };

const getSelectorItemsFromCampaignBooks = (
  books?: BookContent[],
): ProductVariantList => {
  const productVariants =
    books?.map((book, index) => {
      const subTitle = book.name;
      const title = `BOK ${(index += 1)}`;

      return {
        key: book.sku,
        title: title ?? "",
        subTitle: subTitle ?? "",
        image: book.image,
        slug: book.slug,
      };
    }) ?? [];
  return { items: productVariants };
};

export const SubscriptionCampaignFocus = ({
  data: { _id, title, subscription, description, tagRef, ...content },
  subscriptionProduct,
  index,
  universeTag,
  ...section
}: SubscriptionCampaignFocusProps) => {
  const booksList = getSelectorItemsFromCampaignBooks(subscription?.books);
  const [startBookItem, setStartBookItem] = useState(booksList.items[0]);

  if (!subscription) return null;

  const link = getPageLink({
    pageType: "checkout",
    universeTag,
    params: subscriptionProduct.sku,
    searchParams: subscriptionProduct.isSeries
      ? { initialSku: startBookItem.key }
      : {},
  }).linkAs?.toString();

  return (
    <ImageContentSection
      id={_id}
      index={index}
      aria-labelledby={`subscription-capmaign-focus-${_id}`}
      {...section}
      {...content}
      className="prose col-span-full flex flex-col space-x-4 overflow-hidden rounded py-8 sm:col-span-3 md:col-span-5"
      imageClassname="p-12 rounded-[3.5rem]" //the border radius needs to be more then the padding or it will not show upp, this dose look right in all cases
      link={link}
    >
      <div>
        {tagRef && (
          <div
            style={{
              ...getCSSPropertiesTheme(tagRef.theme),
            }}
            className={classNames(
              getThemeClassName(tagRef.theme ? "primary" : null),
              "flex w-fit gap-3.5 rounded-full px-3 py-1 text-sm",
            )}
          >
            <SanityIcon data={{ ...tagRef.icon }} size={24} />
            <span
              className={classNames(
                "flex flex-col justify-center font-semibold",
              )}
            >
              {tagRef.tagText}
            </span>
          </div>
        )}
        <h1 className="heading-01 leading-120">{subscription.name}</h1>
        {description && (
          <div className="prose">
            <PortableTextSection
              value={truncatePortableText(description, 10)}
            />
          </div>
        )}
        {subscriptionProduct?.isSeries ? (
          <SeriesSubscriptionHeaderContent
            subscription={subscriptionProduct}
            booksList={booksList}
            universeTag={universeTag}
            startBookItem={startBookItem}
            onChange={setStartBookItem}
          />
        ) : (
          <SubscriptionHeaderContent
            subscription={subscriptionProduct}
            universeTag={universeTag}
          />
        )}
      </div>
    </ImageContentSection>
  );
};

export default SubscriptionCampaignFocus;
