"use client";

import { Smiley, SmileyMeh, SmileySad } from "@phosphor-icons/react";

const SmileyVariant = ({
  title,
  onSubmit,
}: {
  title: string;
  onSubmit: () => void;
}) => {
  return (
    <>
      <h3 className="heading-03 pb-6 text-center">{title}</h3>
      <div className="flex justify-center gap-6">
        <button className="rounded-full bg-[#FDDCD5] p-1" onClick={onSubmit}>
          <SmileySad size={36} />
        </button>
        <button className="rounded-full bg-[#FFEAB5] p-1" onClick={onSubmit}>
          <SmileyMeh size={36} />
        </button>
        <button className="rounded-full bg-[#CDF1C1] p-1" onClick={onSubmit}>
          <Smiley size={36} />
        </button>
      </div>
    </>
  );
};

export default SmileyVariant;
