"use client";

import { Checkbox } from "@cappelendamm/design-system";
import { useState } from "react";

interface CheckboxState {
  [key: string]: boolean;
}

const CheckboxVariant = ({
  title,
  checkboxes,
  onSubmit,
}: {
  title: string;
  checkboxes: string[];
  onSubmit: () => void;
}) => {
  const initialCheckboxState = checkboxes.reduce(
    (acc, label) => ({
      ...acc,
      [label]: false,
    }),
    {} as CheckboxState,
  );

  const [checkboxState, setCheckboxState] =
    useState<CheckboxState>(initialCheckboxState);
  const [error, setError] = useState("");

  const handleChange =
    (label: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setCheckboxState((prevState) => ({ ...prevState, [label]: checked }));
      setError("");
    };

  const isAnyCheckboxChecked = Object.values(checkboxState).some(
    (value) => value,
  );

  return (
    <div className="flex flex-col">
      <h3 className="heading-03 mb-1">{title}</h3>
      <p className="leading-120 mb-2 h-4 text-sm text-[#EB433F]">{error}</p>
      <div className="mb-6 flex flex-col gap-3">
        {checkboxes.map((checkbox) => (
          <label key={checkbox} className="flex gap-2">
            <Checkbox
              checked={checkboxState[checkbox]}
              onChange={handleChange(checkbox)}
            />
            <p>{checkbox}</p>
          </label>
        ))}
      </div>
      <button
        type="button"
        className="focus:shadow-outline leading-120 w-full self-center rounded-full bg-[#9A4234] px-12 py-4 text-lg font-semibold text-white hover:bg-[#541E15] focus:outline-none"
        onClick={() => {
          if (isAnyCheckboxChecked) {
            onSubmit();
          } else {
            setError("Du må velge minst ett alternativ");
          }
        }}
      >
        Send
      </button>
    </div>
  );
};

export default CheckboxVariant;
