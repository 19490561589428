"use client";

import { ContentData } from "@/cms/types";
import { TrackingItem, TrackingSection } from "@/tracking/types";
import { getTrackingDataset } from "@/tracking/utils";
import { Confetti } from "@phosphor-icons/react";
import classNames from "classnames";
import { FC, useState } from "react";
import { SectionProps } from "../Section";
import { FeedbackSection } from "./types";
import CheckboxVariant from "./variants/CheckboxVariant";
import RadioButtonVariant from "./variants/RadioButtonVariant";
import SmileyVariant from "./variants/SmileyVariant";

export type FeedbackProps = ContentData<FeedbackSection> &
  Omit<SectionProps, "_id">;

export const Feedback: FC<FeedbackProps & JSX.IntrinsicElements["section"]> = ({
  data: { _id, _type, internalTitle, title, variant, alternatives },
  index,
  experimentGroup,
  universeTag,
}) => {
  const sectionTracking = getTrackingDataset<
    TrackingSection & Partial<TrackingItem>
  >({
    sectionId: _id ?? "section-id-unknown",
    sectionName: internalTitle?.replaceAll(" ", "-") ?? "feedback_section_name",
    sectionType: "feedbackSection",
    sectionIndex: index,
  });

  const [submitted, setSubmitted] = useState(false);

  if (!title) return null;

  return (
    <section
      className={classNames({
        "bg-[#CDF1C1]": submitted,
      })}
      {...sectionTracking}
      data-testid="feedback-section"
    >
      <div className="mx-auto max-w-96 px-4 py-6">
        {!submitted ? (
          <>
            {variant === "smiley" && (
              <SmileyVariant
                title={title}
                onSubmit={() => setSubmitted(true)}
              />
            )}
            {variant === "radio" && alternatives && (
              <RadioButtonVariant
                title={title}
                options={alternatives}
                onSubmit={() => setSubmitted(true)}
              />
            )}
            {variant === "checkbox" && alternatives && (
              <CheckboxVariant
                title={title}
                checkboxes={alternatives}
                onSubmit={() => setSubmitted(true)}
              />
            )}
          </>
        ) : (
          <div className="flex flex-col items-center justify-center gap-4 py-4">
            <Confetti size={32} />
            <h3 className="text-heading03">Tusen takk for svaret ditt!</h3>
          </div>
        )}
      </div>
    </section>
  );
};

export default Feedback;
