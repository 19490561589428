"use client";

import { AccordionSection as AccordionSectionTypes } from "./types";
import { FC } from "react";
import classNames from "classnames";
import { TrackingItem, TrackingSection } from "@/tracking/types";
import { getTrackingDataset } from "@/tracking/utils";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import { SectionProps } from "../Section";
import { Accordion } from "@/components/Accordion";
import { PortableTextSection } from "@/portable-text/PortableTextSection";
import { ContentData } from "@/cms/types";

export type AccordionSectionProps = ContentData<AccordionSectionTypes> &
  Omit<SectionProps, "_id">;

export const AccordionSection: FC<
  AccordionSectionProps & JSX.IntrinsicElements["section"]
> = ({
  data: { _id, internalTitle, accordions, title, container, variant, theme },
  index,
}) => {
  const sectionTracking = getTrackingDataset<
    TrackingSection & Partial<TrackingItem>
  >({
    sectionId: _id ?? "section-id-unknown",
    sectionName:
      internalTitle?.replaceAll(" ", "-") ?? "accordion_section_name",
    sectionType: "accordionSection",
    sectionIndex: index,
  });
  return (
    <section
      className={classNames(
        "lg:mx-body bg-primary mb-12 flex flex-col items-center overflow-x-scroll px-[var(--body-mx)] sm:overflow-visible",
        { "lg:w-body lg:mx-body lg:px-0": container === "default" },
      )}
      {...sectionTracking}
      style={{
        ...(variant === "color" ? getCSSPropertiesTheme(theme) : {}),
      }}
      data-testid="accordion-section"
    >
      <div className="w-body p-3">
        <h3
          className={classNames("heading-03", {
            "text-[var(--fg-primary)]": variant === "color",
          })}
        >
          {title}
        </h3>
        <div className={classNames("flex flex-col gap-3 py-3")}>
          {accordions?.map(({ _key, title, icon, text, accordionText }) => (
            <Accordion
              key={_key}
              index={index}
              icon={icon}
              title={title}
              text={text}
            >
              <PortableTextSection value={accordionText} />
            </Accordion>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AccordionSection;
