import { Anchor } from "@/navigation";
import { getPageLink } from "@/navigation/selectors";
import { formatPrice } from "@/products/selectors";
import { Button } from "@cappelendamm/design-system";
import { SubscriptionProduct } from "@cappelendamm/e-commerce-services";
import { FC } from "react";

export const SubscriptionHeaderContent: FC<{
  subscription: SubscriptionProduct;
  universeTag: string;
}> = ({ subscription, universeTag }) => {
  return (
    <div className="overflow-visible">
      <div className="grid gap-4 py-4">
        <div className="grid grid-flow-col">
          <span className="heading-03">
            {formatPrice({
              currency: subscription.recurringPrice.currency,
              value: subscription.recurringPrice.price,
              type: "monthly",
            })}
          </span>
        </div>

        <Button
          as={Anchor}
          {...getPageLink({
            pageType: "checkout",
            universeTag,
            params: subscription.sku,
          })}
          className="mt-5 w-fit"
          variant="primary"
        >
          Bli abonnent
        </Button>
      </div>
    </div>
  );
};
