import { getImageAspectRatio, getImageProps } from "@/cms/images/getImageProps";
import Image from "next/image";
import { SelectorItem } from "./ProductVariantSelect";

const BookDisplay = (book?: SelectorItem) => {
  return (
    <div className="grid max-h-80 w-full gap-4 rounded border bg-white px-4 py-2">
      <div className="flex items-center justify-between gap-3 text-black">
        <div className="flex gap-3">
          <div>
            {book?.image && (
              <Image
                {...getImageProps({
                  ...book.image,
                  ...getImageAspectRatio({
                    height: 70,
                    aspectRatio:
                      book.image.asset.metadata.dimensions.aspectRatio,
                  }),
                })}
                className="w-fit min-w-[42px] rounded-sm"
              />
            )}
          </div>
          <span className="grid self-center text-left">
            <span className="font-semibold">{book?.title}</span>
            <span className="text-sm text-gray-800">
              {book?.subTitle ?? ""}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default BookDisplay;
