import { ProductVariantList } from "@/cms/types/objects";
import {
  ProductVariantSelect,
  SelectorItem,
} from "@/components/ProductVariantSelect";
import { Anchor } from "@/navigation";
import { getPageLink } from "@/navigation/selectors";
import { formatPrice } from "@/products/selectors";
import { Button } from "@cappelendamm/design-system";
import { SubscriptionProduct } from "@cappelendamm/e-commerce-services";
import classNames from "classnames";
import { FC } from "react";
import BookDisplay from "./BookDisplay";
import { SubscriptionOverride } from "@/products/types";

export const SeriesSubscriptionHeaderContent: FC<{
  subscription: SubscriptionProduct & Partial<SubscriptionOverride>;
  booksList: ProductVariantList;
  universeTag: string;
  startBookItem: SelectorItem;
  onChange: (value: SelectorItem) => void;
}> = ({ subscription, booksList, universeTag, startBookItem, onChange }) => {
  return (
    <div className={classNames("overflow-visible")}>
      <div className="grid gap-1">
        <div className="flex py-4">
          <span className="heading-03">
            {subscription.overridePriceText ??
              formatPrice({
                currency: subscription.recurringPrice.currency,
                value: subscription.recurringPrice.price,
                type: "monthly",
              })}
          </span>
        </div>
        <span className="heading-04">
          Hvilken bok skal abonnementet starte på?
        </span>
        <div className="relative z-10 grid sm:max-w-[510px]">
          {subscription.productSelector ? (
            <ProductVariantSelect
              {...booksList}
              value={startBookItem}
              onChange={(book) => onChange(book)}
              subscription
            />
          ) : (
            <BookDisplay {...startBookItem} />
          )}
        </div>

        <Button
          as={Anchor}
          {...getPageLink({
            pageType: "checkout",
            universeTag,
            params: subscription.sku,
            searchParams: { initialSku: startBookItem.key },
          })}
          className="mt-5 w-fit !no-underline"
          variant="primary"
        >
          Bli abonnent
        </Button>
      </div>
    </div>
  );
};
